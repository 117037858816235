import React from 'react';
import "../../assets/scss/friends.scss"
import UserBlock from "../../components/UserBlock";
import Head from "../../components/Head";
import {useInitData, useWebApp} from "@vkruglikov/react-telegram-web-app";
import {useUserStore} from "../../states/user";

const Friends = ({}) => {
    const WebApp = useWebApp();
    const {referals} = useUserStore()
    const [initDataUnsafe, initData] = useInitData();
    const share = ()=>{
        WebApp.openTelegramLink(`https://t.me/share/url?url=https://t.me/azxcsdasqwezxcdasD_bot/deasoftclcl?startapp=${initDataUnsafe.user.id}&text=Присоединяйся к нам!`)
        WebApp.close()
    }
    return (
        <div className='panel panel-friends'>
            <Head/>
            <div className="panel-friends__content">
                <div>
                    <h2 className={"fw-600 fs-45 title"}>Пригласите друзей</h2>
                    <h3 className={"fw-300 subtitle"}>Вы и ваш друг получите бонусы</h3>
                </div>

                <div className="friends-list">
                    <h3 className={"fw-500 fs-27 friends-list__title"}>
                        Ваши друзья ({referals ? referals.length : 0})
                    </h3>
                    <div className="friends-list__list">
                        {!referals || referals.length === 0 ? (
                            <h3>Вы ещё никого не пригласили</h3>
                        ): referals.map((user) => (
                            <UserBlock user={user}/>
                        ))}
                    </div>
                    <div className="panel-friends__info">
                        <span className={"fm-orb fw-500 fs-20"}>+5 000 Вам и другу</span>
                    </div>
                </div>
                <button onClick={share} className={"btn btn-success btn-w100 fs-25 fw-300 panel-friends__content-button"}>
                    Пригласить друга
                </button>
            </div>
        </div>
    )

}

export default Friends;