import React from'react';
import {useUserStore} from "../states/user";



const Error = () => {
    const {error} = useUserStore();
    const reloadPage =  () =>{
        window.location = "/";
    }
    return (
        <div className={"error"}>
            <h1 className={"error__title"}>{error}</h1>
            <button onClick={reloadPage} className={"btn btn-success"}>
                Перезагрузить приложение
            </button>
        </div>
    )

}
export default Error;