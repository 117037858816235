import React, {useEffect} from 'react';
import {useSpring, animated, useSpringRef, useChain, useSpringValue} from "@react-spring/web";
import {calc} from "../utils/other";
import {getRandomArbitrary} from "../utils";


const ClickText = ({count,position}) => {
    const moveRef = useSpringRef();
    const [style, xysApi] =  useSpring(() => ({ref:moveRef,opacity:1,fontSize:35, left: position.x,top: position.y}));
    useEffect(()=>{
        xysApi.start({
            left:position.x,
            top:position.y-getRandomArbitrary(60,200),
            fontSize: 15,
            opacity: 0,
            config: {
                friction: 50,
            }
        })
    },[])
    return(
        <animated.div className={"animated-click fm-orb"} style={{...style, position: 'absolute'}}>
            +{count}
        </animated.div>
    )
}
export default React.memo(ClickText);