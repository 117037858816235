import React from 'react';
import "../../assets/scss/home.scss"
import Head from "../../components/Head";
import {useUserStore} from "../../states/user";
import {socket} from "../../components/SocketIO/socket"
import CoinBlock from "./components/CoinBlock";
import TapBlock from "./components/TabBlock";

const Home = ({}) => {
    const {isNew,progress,coins,addCoins,addProgress} = useUserStore();


    return (
        <div className='panel panel-home'>
            <Head/>
            <CoinBlock/>
            <TapBlock/>
        </div>
    )

}

export default Home;