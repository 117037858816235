import React from'react';
import star from "../../../assets/img/star.png"
import {useUserStore} from "../../../states/user";

const CoinBlock = ({}) => {
    const {isNew,progress,coins,addCoins,addProgress} = useUserStore();

    return (
        <div className="coin-block">
            <div className="score-box">
                <img src={star} alt=""/>
                    <span>{coins}</span>
            </div>
            <div className="score-info">
                <div className="information">
                    <span className="number">+1</span>
                    <span className="text">монет за тап</span>
                </div>
                <div className="information">
                    <span className="text">прибыль в час</span>
                    <span className="number">40</span>
                </div>
            </div>
        </div>
    )
}
export default CoinBlock;