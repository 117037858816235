import React, {useEffect} from 'react';
import {socket} from "../components/SocketIO/socket";

const useClick = (element,click=()=>{},logEvents=false) => {
    let tpCache = [];

    const log = (name,ev,what) =>{
        console.log(name,ev,what);
    }

    const start_handler = (ev)=> {
        console.log(ev);
        // If the user makes simultaneious touches, the browser will fire a
        // separate touchstart event for each touch point. Thus if there are
        // three simultaneous touches, the first touchstart event will have
        // targetTouches length of one, the second event will have a length
        // of two, and so on.
        ev.preventDefault();
        // Cache the touch points for later processing of 2-touch pinch/zoom
        if(!ev.targetTouches){

        }else
            if (ev.targetTouches.length == 2) {
                for (var i=0; i < ev.targetTouches.length; i++) {
                    tpCache.push(ev.targetTouches[i]);
                }
            }
        if (logEvents) log("touchStart", ev, true);
        update_background(ev);
    }

    const move_handler = (ev) => {
        // Note: if the user makes more than one "simultaneous" touches, most browsers
        // fire at least one touchmove event and some will fire several touchmoves.
        // Consequently, an application might want to "ignore" some touchmoves.
        //
        // This function sets the target element's outline to "dashed" to visualy
        // indicate the target received a move event.
        //
        ev.preventDefault();
        if (logEvents) log("touchMove", ev, false);
        // To avoid too much color flashing many touchmove events are started,
        // don't update the background if two touch points are active
        if (!(ev.touches.length == 2 && ev.targetTouches.length == 2))
            update_background(ev);

        // Set the target element's outline to dashed to give a clear visual
        // indication the element received a move event.
        //ev.target.style.outline = "dashed";

    }
    function isTouchDevice() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }
    const end_handler = (ev) => {
        ev.preventDefault();
        if (logEvents) log(ev.type, ev, false);
        if (!ev.targetTouches || ev.targetTouches.length == 0) {
            click(ev);
        }
    }

    const set_handlers = (el) => {
        if(logEvents)
            log("handler",el)
        if(isTouchDevice()){
            el.ontouchstart = start_handler;
            el.ontouchmove = move_handler;

            el.ontouchcancel = end_handler;
            el.ontouchend = end_handler;
        }else{
            el.onmousedown = start_handler;
            el.onmouseup = end_handler;
        }
        // Install event handlers for the given element
        //

        // Use same handler for touchcancel and touchend
        //
       //

    }

    const update_background = (ev) => {
        // Change background color based on the number simultaneous touches
        // in the event's targetTouches list:
        //   yellow - one tap (or hold)
        //   pink - two taps
        //   lightblue - more than two taps
        if(ev.targetTouches)
        switch (ev.targetTouches.length) {
            case 1:
                /*console.log("yellow");
               click(ev);*/
                break;
            case 2:
                /*console.log("yellow2");
                click(ev);*/
                break;
            default:
                /*console.log("yellow3");
                click(ev);*/
        }
    }

    useEffect(() => {
        if(logEvents)
            log("INIT",element.current)
        if (element && element.current) {
            set_handlers(element.current);
        }
        return () => {

        };
    },[element])


}
export default useClick;
