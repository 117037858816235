import React from 'react';
import './assets/scss/main.scss';
import { WebAppProvider, MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from "./Panels/Home/Home";
import Friends from "./Panels/Friends/Friends";
import Error from "./Panels/Error";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/friends",
                element: <Friends />,
            },
            {
                path: "/error",
                element: <Error />,
            },
        ],
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <WebAppProvider
          options={{
              smoothButtonsTransition: true,
          }}
      >
      <RouterProvider router={router} />
      </WebAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
