import logo from './logo.svg';
import Home from "./Panels/Home/Home";
import {Outlet, useNavigate} from "react-router-dom";
import Epic from "./components/Epic";
import {socket} from './components/SocketIO/socket';
import {useEffect, useState} from "react";
import {useUserStore} from "./states/user";
import {MainButton, useInitData} from '@vkruglikov/react-telegram-web-app';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import InfiniteScroll from "react-infinite-scroll-component";

function App() {
    //const [initDataUnsafe] = useInitData();
    const [initDataUnsafe, initData] = useInitData();
    const [loaded, setLoaded] = useState(false);
    const WebApp  = useWebApp();
    const {setIsNew,isNew,setAll,setError,error} = useUserStore();
    const navigate = useNavigate();
    useEffect(()  =>  {
        console.log("initDataUnsafe",initDataUnsafe);
        WebApp.expand();
        window.Telegram.WebApp.disableVerticalSwipes();
        /* console.log("WebApp",WebApp.initDataUnsafe);*/
        if(initData)
            socket.connect();

    },[initData,initDataUnsafe])
    useEffect(() => {
        function onConnect() {
            console.log("onConnect");
        }

        function onDisconnect() {
            setLoaded(false)
        }

        const updateMe = (me) =>{
            setAll(me)
        }

        function onHello(me) {
            setIsNew(me.isNew);
            navigate('/');
            setLoaded(true);
            //setFooEvents(previous => [...previous, value]);
        }
        const onError = (err) => {
            setLoaded(true);
            setError(err);
            navigate('/error');
        }

        socket.on("error",onError)

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('hello', onHello);
        socket.on('user', updateMe);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('hello', onHello);
            socket.off('error', onError);
        };
    }, []);
    if (!loaded && !error)
        return (
            <div className="App">
                <div className="loading">
                    <span className="fw-600 fm-orb">Loading...</span>
                    <div className="loader">
                    </div>
                </div>
            </div>
        )

    return (

            <div className="App">
                <Outlet/>
                {!error &&
                    <Epic/>
                }
            </div>

    );
}

export default App;
