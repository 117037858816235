import {io} from 'socket.io-client';
import {useInitData} from "@vkruglikov/react-telegram-web-app";


// "undefined" means the URL will be computed from the `window.location` object
const URL = 'https://click.mvc-soft.ru/';
//window.Telegram.WebApp.initData
export const socket = io(URL,
    {
        transports:["websocket", "polling"],
        path: "/socket.io/",
        autoConnect: false,
        query: {
            //initData: "query_id=AAEsRA0TAAAAACxEDRO4HT68&user=%7B%22id%22%3A319636524%2C%22first_name%22%3A%22%D0%AD%D1%85%D1%85%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22leroije%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1720698535&hash=282524e78010c4d4b215752391a95665688638220e4c812022042fa4d3278e21",
            initData: window.Telegram.WebApp.initData
        }
    });