import React, {useState, useRef, useEffect} from 'react';
import useClick from "../../../hooks/useClick";
import {socket} from "../../../components/SocketIO/socket";
import {useUserStore} from "../../../states/user";
import {getElementPosition} from "../../../utils";
import ClickText from "../../../components/ClickText";
import {useSpring, animated, useSpringRef, useChain, useSpringValue} from "@react-spring/web";
import {calc} from "../../../utils/other";


const TapBlock = () => {
    const {clickStep} = useUserStore()
    const cardRef = useRef();

    const moveRef = useSpringRef();
    const [{ xys,scale }, xysApi] = useSpring(() => ({ref:moveRef, xys: [0, 0, 1],scale:1}))
    const [animationsClicks,setAnimationsClicks] = useState([]);
    const clicksRef = useRef();
    clicksRef.current = animationsClicks;
    const clickRef = useRef();
    const click = (e) => {
        let oldValues = xys.animation.toValues;
        const rect = cardRef.current.getBoundingClientRect();
        let clickPos = {
            x:e.changedTouches ? e.changedTouches[0].pageX : e.pageX,
            y:e.changedTouches? e.changedTouches[0].pageY : e.pageY,
        }
        xysApi.start({
            xys: calc(clickPos.x, clickPos.y, rect,true),
            from:{
                scale:0.98
            },
        })
        setTimeout(()=> xysApi.start({xys:[0, 0, 1],scale:1}),100);
        let pos =  getElementPosition(e.currentTarget);
        let x = clickPos.x - pos.x-10;
        let y = clickPos.y - pos.y-30;
        let clicks  = [...clicksRef.current];
        clicks.push({x,y});

        socket.emit("click")

        setAnimationsClicks([...clicks]);
    }
    const handleMouseLeave = () =>
        xysApi.start({
            xys: [0, 0, 1],
            scale:1
        })
    const handleMouseMove = e => {
        const rect = cardRef.current.getBoundingClientRect();
        xysApi.start({
            xys: calc(e.clientX, e.clientY, rect),
            scale:1
        })
    }
    const trans = (x, y, s) =>
        `perspective(600px) rotateX(${x}deg) rotateY(${y}deg)`
    useClick(clickRef,click)

    useEffect(()  =>  {
        if(animationsClicks.length > 25){
            //Удалить старые 10 элементов c конца
            let clicks  = [];
            setAnimationsClicks(clicks);
        }
    },[animationsClicks])

    return(
        <div ref={clickRef} className="tap-block">
            {animationsClicks.map((item, index) => (
                    <ClickText position={item} key={index} count={clickStep}/>
                )
            )}
            <animated.div
                ref={cardRef}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                style={{transform: xys.to(trans),scale:scale}}
                className="round">

            </animated.div>
        </div>
    )
}
export default TapBlock;