import React, {useEffect} from 'react';
import {useUserStore} from "../states/user";
import img from "../assets/img/img.jpg";
import logo from "../assets/img/logo.png";
import {useInitData} from "@vkruglikov/react-telegram-web-app";

const Head = () => {
    const [initDataUnsafe, initData] = useInitData();
    const {isNew,photo} = useUserStore();

    return (
        <header className="navheader">
            <div className="user-info">
                <div className="avatar">
                    <img src={photo ? `data:image/png;base64, ${photo}` : img} alt=""/>
                </div>
                {/*<span className="username">{initDataUnsafe?.user?.first_name}</span>*/}
            </div>
            <div className="logobox">
                <img src={logo} alt=""/>
            </div>
            <div className="navtools">

            </div>
        </header>
        )
}

export default Head;