import React from'react';
import "../assets/scss/epic.scss"
import {useLocation, useNavigate} from "react-router-dom";
import coin from "../assets/img/chinese-coin.png"
import flash from "../assets/img/flash.png"
import friends from "../assets/img/add-friend.png"


const Epic = ({}) =>{
    const navigate = useNavigate();
    const location = useLocation()
    const isActive = tab => location.pathname.split("/")[1] ===  tab ? "active" : "";

    return (
        <nav className="nav-block">
            <ul className="menus">
                <li className={isActive("")}>
                    <a onClick={()=>navigate("/")}>
                        <img src={coin} alt=""/>
                        <span>Главная</span>
                    </a>
                </li>
                {/*<li className={isActive("flash")}>
                    <a onClick={()=>navigate("flash")}>
                    <img src={flash} alt=""/>
                    <span>Призы</span>
                    </a>
                </li>*/}
                <li className={isActive("friends")}>
                    <a onClick={()=>navigate("/friends")}>
                    <img src={friends} alt=""/>
                    <span>Друзья</span>
                </a>
                </li>
            </ul>
        </nav>
    )
}
export default Epic