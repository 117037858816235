import React from'react';
import img from '../assets/img/img.jpg';

const UserBlock = ({user,place,moreGlass=false}) => {
    return (
        <div  className={`user-rating glass ${moreGlass? "glass-more" : ""}`}>
            <div className="user-rating__main">
                <img src={user.photo ? `data:image/png;base64,${user.photo}` : img} alt=""/>
                <div className="user-rating__info">
                    <span className={"fm-orb fs-30 name fw-500"}>{user.first_name}</span>
                    <div className="user-rating__coins">
                        <span className={"fm-orb fw-500"}>
                        {user.coins}
                    </span>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default UserBlock;