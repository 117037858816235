import { create } from 'zustand'

export const useUserStore = create((set) => ({
    isNew: false,
    photo:null,
    error:"",
    clickStep:1,
    coins:0,
    progress:0,
    progressForNextLvl:100,
    lvl:0,
    setAll:  (variables)  => set((state)  =>  {
        return {...state,...variables}
    }),
    addCoins:  (coins)  => set((state)  =>  ({...state, coins:state.coins + coins})),
    setError: (error) => set((state)=>({...state, error:error})),
    addProgress: (progress) => set((state) => {
        return {...state, progress:state.progress + progress}
    }),
    /*setProgress: (progress) =>
      set((state)  =>  {
          let newProgress  = state.progress + progress;
          let newLvl = state.lvl;
          if(newProgress >= 100){
              newLvl++;
              return {
                  ...state,
                  progress:100
              }
          }
      }),*/
    setIsNew: (isNew) => set((state) => ({ ...state,isNew })),
}))
